const listItems = document.querySelectorAll(".expSideMenuItemname");

listItems.forEach((item, tabIndex) => {
  item.addEventListener("click", () => {
    document.documentElement.style.setProperty("--exp-item-index", tabIndex);
    listItems.forEach((item) => {
      item.classList.remove("expItemSelected");
    });
    item.classList.add("expItemSelected");

    // Exp sections
    expSections.forEach((item, expIndex) => {
      // If choosen index and the section index is same
      if (tabIndex === expIndex) {
        item.classList.remove("displayNone");
        item.classList.add("fadeInExperienceSection");
      } else {
        item.classList.add("displayNone");
      }
    });
  });
});

const expSections = document.querySelectorAll("#expPanel");
